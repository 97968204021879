<template>
    <v-content>
        <!--<v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>-->

        <v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="dialogEdit" max-width="500px">
            <editForm
                    v-if="dialogEdit"
                    :editData="editedDataInquiry"
                    :success="success"
                    @formResponse="onResponse"
            />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-dialog v-model="dialogCreate" max-width="500px">
            <createForm :success="success" @formResponse="onResponse"/>
        </v-dialog>
        <v-container fluid>

            <vue-good-table mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true,  perPageDropdown: [20, 50, 100,200]}"
                            :rows="roomRows"
                            :columns="columnsRoom">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <button class=" white--text cyan darken-2" dark style="padding:5px; border-radius:5px" @click="editItem(props.row)">{{$t('view_details')}}</button>
                    </span>
                </template>
            </vue-good-table>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import editForm from "./EditInquiry";
    import deleteListData from "@/components/deleteModal";
    import {parseJwt} from "@/mixins/parseJwt";

    export default {
        name: "package_create_form",
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            editForm,
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                columnsRoom: [
                    {
                        label: 's_no',
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        field: "name"
                    },
                    {
                        label: 'address',
                        field: "address"
                    },
                    {
                        label: 'email',
                        field: "email"
                    },
                    {
                        label: 'contact_no',
                        field: "contactNumber"
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false
                    }
                ],
                roomRows: [],
                totalRecords: 0,
                serverParamsRoom: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "packageName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
                breadCrumb: [
                    {
                        text: "Dashboard",
                        to: "/MinistryDashboard",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: "Package Setting",
                        disabled: true
                    }
                ],
                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItems();
            this.filterData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            onResponse(data) {
                console.log("ok")
                if (data) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItems();
                } else {
                    this.dialogDelete = false;
                }
            },
            editItem(props) {
                console.log('notidfj', props.inquiryID)
                this.editedDataInquiry = props.inquiryID;
                this.dialogEdit = true;
                // this.dialogEditFare = true;
            },

            deleteItemRoom(props) {
                console.log(props);
                this.dialogDelete = true;
                this.deleteFormData.url = "Report/DeleteNoticeAsync/" + props.inquiryID;
                this.loadItems();
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParamsRoom = Object.assign({}, this.serverParamsRoom, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            async filterData() {
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParamsRoom.perPage,
                    PageNo: this.serverParamsRoom.page,
                    OrderType: this.serverParamsRoom.sort[0].type,
                    OrderBy: this.serverParamsRoom.sort[0].field
                };
                try {
                    axios.post("Shared/GetInquiryListAsync", param).then(response => {
                        this.roomRows = response.data.data;
                        this.totalRecords = response.data.totalCount
                    });


                    // this.totalRecords = response.data.totalCount;
                } catch (e) {
                    if (e.response.data.State == "AccessTokenExpired") {
                        this.apiResponse(e);
                        this.loadItems();
                    }
                }
                return;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>
