<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('navBarLogin.inquiry')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar> 
            <v-container>
                <v-card shaped class="rounded-card mx-auto mt-2" max-width="500">
                    <v-card-title>
                        Full Name: {{editedData.name}}
                    </v-card-title>
                    <v-card-title>
                        Address: {{editedData.address}}
                    </v-card-title>
                    <v-card-title>
                        E-mail: {{editedData.email}}
                    </v-card-title>
                    <v-card-title>
                        Contact no.: {{editedData.contactNumber}}
                    </v-card-title><br/>
                    <v-card-subtitle>
                        {{editedData.message}}
                    </v-card-subtitle>

                </v-card>
            </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157, 25, 25)" @click="dialogueClose">{{$t('back')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "EditInquiry",
        props: ["success", "editData"],
        computed: {
            SubjectErrors() {
                const errors = [];
                if (!this.$v.editedData.subject.$dirty) return errors;
                !this.$v.editedData.subject.required &&
                    errors.push("Subject is required.");
                return errors;
            },
            TextErrors() {
                const errors = [];
                if (!this.$v.editedData.text.$dirty) return errors;
                !this.$v.editedData.text.required &&
                    errors.push("Text is required.");
                return errors;
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    subject: "",
                    text: "",
                    isActive: 0,
                    subjectNp: "",
                    textNp: "",

                },
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                subject: { required },
                text: { required },
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                const response = await axios.get("Shared/GetInquiryListAsyncByID/" + this.editData);
                console.log(response.data)
                this.editedData = response.data
                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        isActive: this.editedData.isActive,
                        subject: this.editedData.subject,
                        subjectNp: this.editedData.subjectNp,
                        text: this.editedData.text,
                        textNp: this.editedData.textNp,
                        noticeID: this.editedData.noticeID
                    };
                    axios.post("Report/UpdateNoticeAsync", param).then(response => {
                        if (response.data.success) {
                            this.updated = true;
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Notice Updated Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Notice, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()

                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>